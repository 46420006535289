<template>
    <div class="grid grid-cols-12 gap-4">
        <div
            v-show="(isMobile && showChatRooms) || !isMobile"
            class="col-span-12 p-3 bg-white rounded-lg md:col-span-4"
        >
            <Users
                ref="users"
                :isReadHistory.sync="isReadHistory"
                :showChatRooms.sync="showChatRooms"
                @onDisconnectMessages="disconnectMessages"
                @showMobileChats="
                    showChats = true;
                    showChatRooms = false;
                "
                @onGetMessages="getMessages"
                @onClearMessages="clearMessages"
            />
        </div>
        <div
            v-show="(isMobile && showChats) || !isMobile"
            class="col-span-12 rounded-lg shadow-lg md:col-span-8 bg-gray-50"
        >
            <Messages
                v-if="currentReceiveUserId !== null"
                ref="messages"
                :showChats.sync="showChats"
                :showChatRooms.sync="showChatRooms"
                :isReadHistory.sync="isReadHistory"
            />
            <SendMessage
                v-if="currentReceiveUserId !== null"
                ref="sendMessage"
                v-permission="['create']"
                :templateMessage="templateMessage"
                :isReadHistory.sync="isReadHistory"
            />
        </div>
        <div class="col-span-12 md:col-span-8 md:col-start-5">
            <ServiceChatTemplateMessage
                v-if="currentReceiveUserId !== null"
                :messageClicked.sync="templateMessage"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// 導入 聊天對象組件
import Users from "./components/FirestoreUsers.vue";
// 導入 聊天對象組件
import Messages from "./components/FirestoreMessages.vue";
// 導入 發送聊天訊息組件
import SendMessage from "./components/FirestoreSendMessage.vue";
// 客服聊天室罐頭訊息
import ServiceChatTemplateMessage from "./components/ServiceChatTemplateMessage.jsx";
import { db } from "@/plugins/firebase";
/**
 * firebaseMessageReaded: 設定指定對象未讀訊息歸 0
 * firebaseUpdateUserUnReadMessageCount: 更新 chat_rooms 中指定對象 所有未讀訊息數量
 * firebaseGetServcieChatUnReadMessageCount: 取得客服聊天室未讀訊息數量
 */
import {
    firebaseMessageReaded,
    firebaseUpdateServiceChatUnReadMessageCount,
    firebaseGetServcieChatUnReadMessageCount,
} from "@/service/firestoreService";

export default {
    name: "FirestoreChats",
    components: {
        Users,
        Messages,
        SendMessage,
        ServiceChatTemplateMessage,
    },
    computed: {
        ...mapState("firestoreChatStore", ["currentReceiveUserId"]),
        ...mapState("userStore", ["user"]),
    },
    data() {
        return {
            setSnapShot: null,
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 判斷是否閱讀歷史訊息 (主要用意是判斷是否將聊天視窗滾到底)
            isReadHistory: false,
            // 判斷是否顯示 聊天對象
            showChatRooms: true,
            // 判斷是否顯示 聊天內容
            showChats: false,
            // 罐頭訊息
            templateMessage: "",
        };
    },
    methods: {
        ...mapMutations("firestoreChatStore", [
            "setCurrentReceiveUserId",
            "setCurrentUserInfo",
        ]),
        // 監聽聊天對象資料
        listenerUserInfo() {
            this.setSnapShot = db()
                .doc(
                    `chat_rooms/${this.serviceChatId}/users/${this.currentReceiveUserId}`
                )
                .onSnapshot(
                    async (snapshot) => {
                        // 未取得聊天對象資料時 將聊天對象資料設定為預設值 且不往下執行
                        if (!snapshot.exists) {
                            this.setCurrentUserInfo({
                                userData: {},
                            });
                            return;
                        }
                        // 判斷當前聊天對象時有未讀訊息時 且非黑名單人員時 要清空未讀訊息
                        if (snapshot.data().unReadMessageCount > 0) {
                            // 取得未讀訊息總計
                            let {
                                unReadMessageCount,
                                unReadMessageCountByProvider,
                                unReadMessageCountByMember,
                            } = await firebaseGetServcieChatUnReadMessageCount();
                            // 判斷身份做對應加減
                            if (snapshot.data().userData.role === 0) {
                                // 會員未讀訊息總計扣除 當前選擇對象未讀訊息數量
                                unReadMessageCountByMember =
                                    unReadMessageCountByMember -
                                    snapshot.data().unReadMessageCount;
                            } else {
                                // 服務商未讀訊息總計扣除 當前選擇對象未讀訊息數量
                                unReadMessageCountByProvider =
                                    unReadMessageCountByProvider -
                                    snapshot.data().unReadMessageCount;
                            }
                            unReadMessageCount =
                                unReadMessageCountByMember + unReadMessageCountByProvider;
                            // 更新客服聊天室未讀訊息總計
                            await firebaseUpdateServiceChatUnReadMessageCount(
                                unReadMessageCount,
                                unReadMessageCountByProvider,
                                unReadMessageCountByMember
                            );
                            // 更新 firebase 聊天對象未讀訊息為 0
                            await firebaseMessageReaded(
                                this.serviceChatId,
                                snapshot.data().userData.banana_id
                            );
                        }

                        this.setCurrentUserInfo(snapshot.data());
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        /**
         * 重新取得聊天室訊息
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        getMessages(userId, receiveUserId) {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.getMessages(userId, receiveUserId);
                }
            });
        },
        // 清空聊天室訊息
        clearMessages() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.clearMessages();
                }
            });
        },
        /**
         * 取消監聽聊天訊息
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對像 id
         */
        async disconnectMessages(userId, receiveUserId) {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.disconnect(userId, receiveUserId);
                }
            });
        },
    },
    watch: {
        currentReceiveUserId(val) {
            if (this.setSnapShot !== null) {
                this.setSnapShot();
            }
            if (val !== null) {
                this.listenerUserInfo();
            }
        },
        "$route.params.id": function (val) {
            /**
             * 因為手機版再進入聊天室後看不到聊天列表
             * 因此要判斷沒有進入聊天室時要清空,聊天對象資料,以及取消監聽聊天對象
             * 在手機版上當聊天對象id 等於 null 時時才能顯示聊天對象列表
             * 當 params.id 等於 undefined 時 代表沒有進入聊天室
             */
            if (val === undefined) {
                // 判斷是否有選擇聊天對象
                if (this.currentReceiveUserId !== null) {
                    // 取消聊天室訊息監聽
                    this.disconnectMessages(
                        this.serviceChatId,
                        this.currentReceiveUserId
                    );
                }
                // 判斷是否有監聽聊天對象資料
                if (this.setSnapShot !== null) {
                    // 有的話先取消監聽
                    this.setSnapShot();
                }
                // 將聊天對象 id 設定為 null
                this.setCurrentReceiveUserId(null);
                // 將聊天對象 設定為 預設值
                this.setCurrentUserInfo({ userData: {} });
                // 清空聊天室訊息
                this.clearMessages();
            }
        },
    },
    async created() {
        if (this.$route.params.id !== undefined) {
            // 設定聊天對象 id
            this.setCurrentReceiveUserId(this.$route.params.id);
            // 取得聊天對象資料
            this.getMessages(this.serviceChatId, this.$route.params.id);
        }
        // 判斷是否有監聽聊天對象資料
        if (this.setSnapShot !== null) {
            // 有的話先取消監聽
            this.setSnapShot();
        }
        // 判斷是否有選擇聊天對象 有的話監聽聊天對象
        if (this.currentReceiveUserId !== null) {
            this.listenerUserInfo();
        }
    },
    beforeDestroy() {
        // 判斷是否有選擇聊天對象
        if (this.currentReceiveUserId !== null) {
            // 取消聊天室訊息監聽
            this.disconnectMessages(this.currentReceiveUserId);
        }
        // 判斷是否有監聽聊天對象資料
        if (this.setSnapShot !== null) {
            // 有的話先取消監聽
            this.setSnapShot();
        }
        // 將聊天對象 id 設定為 null
        this.setCurrentReceiveUserId(null);
        // 將聊天對象 設定為 預設值
        this.setCurrentUserInfo({ userData: {} });
    },
};
</script>
