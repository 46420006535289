import {
    ref,
    set,
    del,
    reactive,
    onMounted,
    onActivated,
    computed,
    defineEmits,
    inject,
    getCurrentInstance,
    defineProps,
} from "@vue/composition-api";

import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";

export default {
    name: "ServiceChatTemplateMessage",
    props: {},
    emits: ["update:messageClicked"],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();

        const serviceTmpMessages = ref({});
        // 取得列表
        async function getList() {
            try {
                const data = await firebase
                    .database()
                    .ref("system_default_message/chats")
                    .get();
                // 判斷是否有訊息
                if (data.exists()) {
                    serviceTmpMessages.value = data.val();
                    console.log("tmp =>", serviceTmpMessages.value);
                }
            } catch (err) {
                console.log("getList err =>", err);
                proxy.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        }

        /**
         * 選擇罐頭訊息
         * @param { type String(字串) } message 罐頭訊息
         */
        function chooseMessage(message) {
            emit("update:messageClicked", message);
        }
        onMounted(async () => {
            await getList();
        });
        return {
            serviceTmpMessages,
            chooseMessage,
        };
    },
    render() {
        return (
            <ul class="grid grid-cols-4 gap-4 my-10 mx-5">
                {Object.keys(this.serviceTmpMessages).map((key) => (
                    <li class="md:col-span-1 col-span-4">
                        <button
                            key={key}
                            class="text-black bg-white rounded-lg text-sm p-2 hover:bg-black hover:text-white transition-all duration-500"
                            onClick={() =>
                                this.chooseMessage(
                                    this.serviceTmpMessages[key]["description"][
                                        "content"
                                    ]["tw"]
                                )
                            }
                        >
                            {
                                this.serviceTmpMessages[key]["description"][
                                    "title"
                                ]["tw"]
                            }
                        </button>
                    </li>
                ))}
            </ul>
        );
    },
};
