var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-[500px]"},[_c('div',{staticClass:"py-2 h-[50px] w-full border-gray-100"},[_c('div',{staticClass:"flex px-2"},[_c('el-input',{staticClass:"flex-1 w-full max-w-full",attrs:{"placeholder":"搜尋名稱","clearable":""},on:{"input":_vm.isEmptyName},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.filterSearch.apply(null, arguments)}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)]),_c('div',{staticClass:"flex justify-center border-b border-gray-100"},[_c('ul',{staticClass:"flex justify-center flex-1 list-none"},_vm._l((_vm.searchTabs),function(tab,key){return _c('li',{key:key,staticClass:"flex-grow text-center text-sm py-2 md:text-base max-w-[250px] cursor-pointer",class:_vm.currentTab === key ? 'border-b-2 border-orange-600' : '',on:{"click":function($event){return _vm.changeTab(key, tab.column, tab.query)}}},[_c('div',{staticClass:"flex justify-center"},[_vm._v(" "+_vm._s(tab.name)+" "),(
                            _vm.unReadMessageCountByMember > 0 &&
                            key === 'members'
                        )?_c('div',{staticClass:"w-[10px] h-[10px] bg-red-500 rounded-full"}):_vm._e(),(
                            _vm.unReadMessageCountByProvider > 0 &&
                            key === 'providers'
                        )?_c('div',{staticClass:"w-[10px] h-[10px] bg-red-500 rounded-full"}):_vm._e()])])}),0)]),_c('div',[_c('ul',{ref:"chatUsers",staticClass:"overflow-y-auto max-h-[500px] bg-white",attrs:{"id":"chatUsers"}},[_vm._l((_vm.users),function(item,index){return _c('li',{key:index,staticClass:"cursor-pointer flex items-center px-5 py-2.5",class:item.userData.banana_id === _vm.currentReceiveUserId
                        ? 'bg-yellow-100'
                        : 'bg-white',attrs:{"id":item.userData.banana_id},on:{"click":function($event){return _vm.changeRoom(item.userData.banana_id)}}},[(_vm.$isEmpty(item.userData.thumbnails))?_c('Avatar',{attrs:{"backgroundImg":item.userData.avatar}}):_c('Avatar',{attrs:{"backgroundImg":item.userData.thumbnails.avatar['360x360']}}),_c('div',{staticClass:"flex-1 ml-3"},[_c('div',{staticClass:"flex"},[_c('h5',{staticClass:"flex-1 font-medium"},[_vm._v(" "+_vm._s(item.userData.name)),(item.isBot === false)?_c('span',{staticClass:"px-3 py-1 ml-2 text-xs text-white bg-red-600 rounded-full font-extralight"},[_vm._v("真人")]):_vm._e()]),_c('span',{staticClass:"pt-1 text-xs font-light text-gray-300 OpenSansFont"},[_vm._v(_vm._s(_vm._f("formatMonthAndDayTime")(-item.lastMsgAt)))])]),_c('div',{staticClass:"flex items-center mt-1"},[_c('p',{staticClass:"flex-1 text-xs text-gray-300"},[_vm._v(" "+_vm._s(_vm.$subString(item.message, 30))+" ")]),(item.unReadMessageCount > 0)?_c('span',{staticClass:"bg-red-600 rounded-full w-5 h-5 text-xs text-white text-center pt-0.5"},[_vm._v(_vm._s(item.unReadMessageCount > 1000 ? 999 : item.unReadMessageCount))]):_vm._e()])])],1)}),(_vm.scrollDownLoading)?_c('li',{staticClass:"min-h-[50px] w-full text-center"},[_c('img',{staticClass:"block w-10 h-10 mx-auto animate-spin",attrs:{"src":"/img/loading.svg","alt":""}})]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }