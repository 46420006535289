<template>
    <div class="min-h-[500px]">
        <div class="py-2 h-[50px] w-full border-gray-100">
            <div class="flex px-2">
                <el-input
                    v-model="searchName"
                    class="flex-1 w-full max-w-full"
                    placeholder="搜尋名稱"
                    clearable
                    @keyup.enter.native="filterSearch"
                    @input="isEmptyName"
                ></el-input>
            </div>
        </div>
        <div class="flex justify-center border-b border-gray-100">
            <ul class="flex justify-center flex-1 list-none">
                <li
                    v-for="(tab, key) in searchTabs"
                    :key="key"
                    class="flex-grow text-center text-sm py-2 md:text-base max-w-[250px] cursor-pointer"
                    :class="
                        currentTab === key ? 'border-b-2 border-orange-600' : ''
                    "
                    @click="changeTab(key, tab.column, tab.query)"
                >
                    <div class="flex justify-center">
                        {{ tab.name }}
                        <div
                            v-if="
                                unReadMessageCountByMember > 0 &&
                                key === 'members'
                            "
                            class="w-[10px] h-[10px] bg-red-500 rounded-full"
                        ></div>
                        <div
                            v-if="
                                unReadMessageCountByProvider > 0 &&
                                key === 'providers'
                            "
                            class="w-[10px] h-[10px] bg-red-500 rounded-full"
                        ></div>
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <ul
                id="chatUsers"
                ref="chatUsers"
                class="overflow-y-auto max-h-[500px] bg-white"
            >
                <li
                    v-for="(item, index) in users"
                    :id="item.userData.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="
                        item.userData.banana_id === currentReceiveUserId
                            ? 'bg-yellow-100'
                            : 'bg-white'
                    "
                    @click="changeRoom(item.userData.banana_id)"
                >
                    <Avatar
                        v-if="$isEmpty(item.userData.thumbnails)"
                        :backgroundImg="item.userData.avatar"
                    />
                    <Avatar
                        v-else
                        :backgroundImg="
                            item.userData.thumbnails.avatar['360x360']
                        "
                    />
                    <div class="flex-1 ml-3">
                        <!-- 只有待約會才會顯示時間 (顯示時間為 開始約會的時間倒數) -->
                        <div class="flex">
                            <h5 class="flex-1 font-medium">
                                {{ item.userData.name
                                }}<span
                                    v-if="item.isBot === false"
                                    class="px-3 py-1 ml-2 text-xs text-white bg-red-600 rounded-full font-extralight"
                                    >真人</span
                                >
                            </h5>
                            <span
                                class="pt-1 text-xs font-light text-gray-300 OpenSansFont"
                                >{{
                                    -item.lastMsgAt | formatMonthAndDayTime
                                }}</span
                            >
                        </div>
                        <div class="flex items-center mt-1">
                            <!-- 最後一筆聊天室內容 -->
                            <p class="flex-1 text-xs text-gray-300">
                                {{ $subString(item.message, 30) }}
                            </p>
                            <!-- 未讀訊息數量 -->
                            <span
                                v-if="item.unReadMessageCount > 0"
                                class="bg-red-600 rounded-full w-5 h-5 text-xs text-white text-center pt-0.5"
                                >{{
                                    item.unReadMessageCount > 1000
                                        ? 999
                                        : item.unReadMessageCount
                                }}</span
                            >
                        </div>
                    </div>
                </li>
                <li
                    v-if="scrollDownLoading"
                    class="min-h-[50px] w-full text-center"
                >
                    <img
                        class="block w-10 h-10 mx-auto animate-spin"
                        src="/img/loading.svg"
                        alt=""
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
import { db } from "@/plugins/firebase";
// 使用 loadash 指定 key 排序方法
import { orderBy } from "lodash/collection";

export default {
    name: "FirestoreUsers",
    components: {
        Avatar,
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("firestoreChatStore", [
            "currentReceiveUserId",
            "unReadMessageCountByMember",
            "unReadMessageCountByProvider",
        ]),
    },
    data() {
        return {
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 聊天對象
            users: [],
            // 監聽變數
            setOnSnapshot: null,
            scrollDownLoading: false,
            // 搜尋暱稱
            searchName: null,
            // 當前tab (預設值為會員)
            currentTab: "members",
            // 預設搜尋條件
            searchTabs: {
                providers: {
                    name: "服務商",
                    key: "providers",
                    column: "userData.role",
                    query: ["in", [1, 2, 3, 4]],
                },
                members: {
                    name: "會員",
                    key: "members",
                    column: "userData.role",
                    query: ["==", 0],
                },
            },
            // 分頁定位點
            paginationKey: 0,
        };
    },
    methods: {
        ...mapMutations("firestoreChatStore", ["setCurrentReceiveUserId"]),
        /**
         * 判斷清空搜尋內容重新搜尋聊天對象
         * @param { type String(字串) } val 搜尋名稱
         */
        async isEmptyName(val) {
            if (this.$isEmpty(val)) {
                await this.filterSearch();
            }
        },

        // 搜尋名稱方法
        async filterSearch() {
            // 判斷有輸入搜尋內容時觸發
            if (!this.$isEmpty(this.searchName)) {
                // 搜尋符合名稱列表
                await this.filterSearchChatUsers(this.serviceChatId);
                return;
            } else {
                // 如果清空搜尋內容時 要先把聊天對象給清空
                this.users = [];
                // 還原 pagination 預設定位 key
                this.paginationKey = 0;

                await this.getChatUsers(
                    this.serviceChatId,
                    this.searchTabs[this.currentTab].column,
                    this.searchTabs[this.currentTab].query
                );
            }
        },
        /**
         * 搜尋聊天對象方法
         * @param { type String(字串) } userId 登入者 id
         */
        filterSearchChatUsers(userId) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                // 取得搜尋搜尋聊天對象 collection
                const getData = db()
                    .collection("chat_rooms")
                    .doc(userId)
                    .collection("users");
                try {
                    // 取得搜尋搜尋聊天對象資料
                    const getSearchUsers = await getData
                        .where(
                            this.searchTabs[this.currentTab].column,
                            this.searchTabs[this.currentTab].query[0],
                            this.searchTabs[this.currentTab].query[1]
                        )
                        .orderBy(`userData.name`)
                        .startAt(`${this.searchName}`)
                        .endAt(`${this.searchName}` + "\uf8ff")
                        .get();

                    // 先清空聊天對象
                    this.users = [];
                    // 如果沒有搜尋到資料時不往下執行
                    if (getSearchUsers.empty) {
                        return;
                    }
                    // 將搜尋取得對象塞入資料聊天對象資料
                    getSearchUsers.forEach((user) => {
                        this.$set(this.users, this.users.length, user.data());
                        // 改變排序方式以 以最後一筆聊天時間做排序
                        this.users = orderBy(
                            this.users,
                            ["lastMsgAt"],
                            ["asc"]
                        );
                    });
                    this.$nextTick(() => {
                        resolve();
                    });
                } catch (err) {
                    reject(err);
                }
            });
        },
        /**
         * 監聽聊天列表
         * @param { type String(字串) } userId 登入者 id
         * @param { type Boolean(布林) } needQuery 判斷是否需要帶入 where 條件
         * @param { type String(字串) } column 搜尋欄位 key
         * @param { type Array(陣列) } query 搜尋方式以及資料 ex: ["==", 1]
         */
        listenChatUsers(userId, column = null, query = [null, null]) {
            return new Promise((resolve, reject) => {
                this.setOnSnapshot = db()
                    .collection("chat_rooms")
                    .doc(userId)
                    .collection("users")
                    .where(`${column}`, query[0], query[1])
                    .orderBy("lastMsgAt", "asc")
                    .limit(100)
                    .onSnapshot(
                        (users) => {
                            users.docChanges().forEach((change) => {
                                // 當有新增資料時會觸發
                                if (change.type === "added") {
                                    this.updateUserData(change.doc.data());
                                }
                                // 當有更新資料時會觸發
                                if (change.type === "modified") {
                                    console.log("work update");
                                    this.updateUserData(change.doc.data());
                                }
                                // 當有刪除資料時會觸發
                                if (change.type === "removed") {
                                    // console.log("Removed user: ", change.doc.data());
                                }
                            });
                            resolve();
                        },
                        (error) => {
                            console.log(error);
                            reject();
                        }
                    );
            });
        },
        /**
         * 更新聊天對象資料
         */
        updateUserData(newData) {
            const index = this.users.findIndex(
                (user) => user.userData.banana_id === newData.userData.banana_id
            );
            if (index !== -1) {
                this.$set(this.users, index, newData);
                this.users = orderBy(this.users, ["lastMsgAt"], ["asc"]);
            } else {
                this.users.unshift(newData);
                // 改變排序方式以 以最後一筆聊天時間做排序
                this.users = orderBy(this.users, ["lastMsgAt"], ["asc"]);
            }
        },
        /**
         * 取得聊天對象列表
         * @param { type String(字串) } userId 登入者 id
         * @param { type Boolean(布林) } needQuery 判斷是否需要帶入 where 條件
         * @param { type String(字串) } column 搜尋欄位 key
         * @param { type Array(陣列) } query 搜尋方式以及資料 ex: ["==", 1]
         */
        async getChatUsers(userId, column = null, query = [null, null]) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    // 聊天對象名單 collection
                    let users = db()
                        .collection("chat_rooms")
                        .doc(userId)
                        .collection("users");
                    // 取得 待赴約 或 待確認聊天對象
                    users = users
                        .where(`${column}`, query[0], query[1])
                        .orderBy("lastMsgAt", "asc")
                        .limit(15);
                    // 判斷分頁定位 key 非 0 時 啟用分頁定位方式
                    if (this.paginationKey !== 0) {
                        // 用分頁 key 定位目前位置 再往後面撈 15筆
                        users = await users
                            .startAfter(this.paginationKey)
                            .get();
                    } else {
                        // 預設第一次先不定位分頁 key 直接取最面的15筆
                        users = await users.get();
                    }
                    // 將分頁定位點改變成 聊天對象 最後一筆資料（firestore 是依據最後一筆資料當定位點)
                    this.paginationKey = users.docs[users.docs.length - 1];
                    if (users.empty) {
                        this.paginationKey = "end";
                        resolve();
                        return;
                    }
                    users.forEach((user) => {
                        // 取得目前資料重複 index
                        const index = this.users.findIndex(
                            (filterUser) =>
                                filterUser.userData.banana_id ===
                                user.data().userData.banana_id
                        );
                        // 判斷目前沒有重複資料才執行
                        if (index === -1) {
                            this.$set(
                                this.users,
                                this.users.length,
                                user.data()
                            );
                        }
                    });
                    // 改變排序方式以 以最後一筆聊天時間做排序
                    resolve();
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            });
        },

        /**
         * 更換 tab
         * @param { type String(字串) } key 聊天 tab key
         * @param { type String(字串) } column 搜尋欄位
         * @param { type Array(陣列) } query 搜尋條件
         */
        async changeTab(key, column, query) {
            // 判斷有監聽對象時
            if (this.setOnSnapshot) {
                // 取消監聽
                this.setOnSnapshot;
            }
            // 設定目前選中 tab
            this.currentTab = key;
            // 將搜尋名稱設定為 null 因為切換 tab 時要回歸預設值
            this.searchName = null;
            // 清空目前聊天對象資料
            this.users = [];
            // 將分頁key設定為預設值
            this.paginationKey = 0;
            await this.getChatUsers(this.serviceChatId, column, query);
            await this.listenChatUsers(this.serviceChatId, column, query);
        },
        /**
         * 設定聊天室資料
         * @param { type String(字串) } userId 使用者 id
         */
        async changeRoom(userId) {
            // 判斷有選擇過服務商聊天對象時才觸發
            if (this.currentReceiveUserId !== null) {
                // 清空聊天室聊天內容
                this.$emit(
                    "onDisconnectMessages",
                    this.serviceChatId,
                    this.currentReceiveUserId
                );
            }
            // 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
            this.setCurrentReceiveUserId(userId);
            // 清空聊天室聊天內容
            this.$emit("onClearMessages");
            // 重新取得對應聊天室聊天內容
            this.$emit("onGetMessages", this.serviceChatId, userId);
            // 顯示手機版的聊天內容
            this.$emit("showMobileChats", true);
            this.$router
                .push({
                    name: "firestore-chats",
                    params: { tab: this.currentTab, id: userId },
                })
                .catch((err) => {});
        },

        /**
         * 分頁機制往下滾到底時觸發
         */
        async scrollDownGetPagination() {
            this.scrollDownLoading = true;
            try {
                await this.getChatUsers(
                    this.serviceChatId,
                    this.searchTabs[this.currentTab].column,
                    this.searchTabs[this.currentTab].query
                );
                this.scrollDownLoading = false;
                this.$nextTick(async () => {
                    const dom = this.$refs.chatUsers;
                    dom.scrollTo({ top: dom.scrollTop });
                });
            } catch (err) {
                console.log(err);
                this.scrollDownLoading = false;
            }
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                if (this.$refs.chatUsers !== undefined) {
                    this.$refs.chatUsers.addEventListener(
                        "scroll",
                        this.getMoreData
                    );
                }
            });
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatUsers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart =
                dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                // 判斷是最後一筆資料時 就停止載入分頁
                if (this.paginationKey === "end") {
                    this.scrollDownLoading = false;
                    console.log("is end");
                    return;
                }
                this.scrollDownGetPagination();
                return;
            }
        },
    },
    async created() {
        // 當網址列有傳送 tab 參數時 修改 currentTab 參數
        if (this.$route.params.tab !== undefined) {
            this.currentTab = this.$route.params.tab;
        }
        this.windowSrcoll();
        await this.getChatUsers(
            this.serviceChatId,
            this.searchTabs[this.currentTab].column,
            this.searchTabs[this.currentTab].query
        );
        await this.listenChatUsers(
            this.serviceChatId,
            this.searchTabs[this.currentTab].column,
            this.searchTabs[this.currentTab].query
        );
    },
    beforeDestroy() {
        if (this.setOnSnapshot !== null) {
            // 取消監聽聊天對象
            this.setOnSnapshot();
        }
        this.$refs.chatUsers.removeEventListener("scroll", this.getMoreData);
    },
};
</script>
